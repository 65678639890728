<template>
  <GradientModal
    :open="modalOpen"
    @close="handleClose"
  >
    <template slot>
      <span class="modal-title">
        {{ $t('sections.agents.agentsWaitingListModal.title') }}
      </span>
      <VerticalTitleInput
        :title="$t('sections.agents.agentsWaitingListModal.fields.email.value')"
        :placeholder="$t('sections.agents.agentsWaitingListModal.fields.email.placeholder')"
        :value="email"
        :valid="validEmail"
        :error-label="$t('components.input.invalidEmail')"
        @input="(e) => handleInput('email', e)"
        class="inputs"
      />
      <PhoneInput
        name="phone-number"
        :title="$t('sections.agents.agentsWaitingListModal.fields.phone.value')"
        :placeholder="$t('sections.agents.agentsWaitingListModal.fields.phone.placeholder')"
        :code-placeholder="
          $t('sections.agents.agentsWaitingListModal.fields.countryCode.placeholder')
        "
        :valid="validPhone"
        :valid-code="validPhoneCountryCode"
        :error-label="$t('components.input.invalidPhone')"
        :value="phone"
        :code-value="countryCode"
        :items="$t('arrays.phoneCountryCodes')"
        @input="(e) => handleInput('phone', e)"
        @codeInput="(e) => handleInput('countryCode', e)"
        class="inputs"
      />
      <BaseCheckbox
        :label="$t('sections.agents.agentsWaitingListModal.consent')"
        :value="consent"
        class="inputs"
        @input="(e) => consent = e"
      />
      <BaseButton
        large
        :label="$t('sections.agents.agentsWaitingListModal.button')"
        :disabled="!validFields"
        @click="handleSubmit"
      />
    </template>
  </GradientModal>
</template>
<script>
import {
  BaseButton,
  GradientModal,
  VerticalTitleInput,
  BaseCheckbox,
  PhoneInput,
} from 'ff-components-lib';
import { validateEmail, validatePhone } from '@/utils';

export default {
  name: 'AgentsWaitingListModal',
  components: {
    BaseButton,
    GradientModal,
    VerticalTitleInput,
    BaseCheckbox,
    PhoneInput,
  },
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      email: undefined,
      countryCode: undefined,
      phone: undefined,
      consent: false,
    };
  },
  computed: {
    validEmail() {
      if (this.email === undefined) {
        return true;
      }
      if (this.email && this.email.length > 0) {
        return validateEmail(this.email);
      }
      return false;
    },
    validPhoneCountryCode() {
      return (
        this.countryCode === undefined
        || (this.countryCode && this.countryCode.length > 0)
      );
    },
    validPhone() {
      if (this.phone === undefined) {
        return true;
      }
      if (this.phone && this.phone.length > 0) {
        return validatePhone(this.phone);
      }
      return false;
    },
    validFields() {
      return (
        this.email
        && this.validEmail
        && this.countryCode
        && this.validPhoneCountryCode
        && this.phone
        && this.validPhone
        && this.consent
      );
    },
  },
  mounted() {
    Object.keys(this.fields).map(async (field) => {
      this[field] = this.fields[field];
    });
  },
  watch: {
    fields: {
      handler() {
        Object.keys(this.fields).map(async (field) => {
          this[field] = this.fields[field];
        });
      },
      deep: true,
    },
  },
  methods: {
    handleInput(field, value) {
      this[field] = value;
      this.$emit('input', { field, value });
    },
    handleSubmit() {
      this.$emit('submit');
    },
    handleClose() {
      this.$emit('close');
      this.email = undefined;
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-title {
  font-size: 24px;
  color: $primary;
  font-weight: 600;
  line-height: 29px;
  text-align: center;
  width: 70%;
}

.inputs {
  width: 85%;
}

@media only screen and (max-width: 900px) {
  .modal-title,
  .inputs {
    width: 90%;
  }
}

@media only screen and (max-width: 770px) {
  .modal-title {
    font-size: 20px;
  }

  .modal-title,
  .inputs {
    width: 100%;
  }
}
</style>

<template>
  <div class="agents-form-section">
    <div class="agents-form-section__inner margin-container">
      <SectionHeader
        icon="mail"
        :title="$t('sections.agents.form.title')"
        :description="$t('sections.agents.form.subtitle')"
        class="align-self-center"
      />
      <div class="inputs">
        <VerticalTitleInput
          :title="$t('sections.agents.form.name.value')"
          :placeholder="$t('sections.agents.form.name.placeholder')"
          :value="name"
          :valid="validName"
          :error-label="$t('components.input.missingField')"
          @input="(e) => handleInput('name', e)"
        />
        <VerticalTitleInput
          :title="$t('sections.agents.form.email.value')"
          :placeholder="$t('sections.agents.form.email.placeholder')"
          :value="email"
          :valid="validEmail"
          :error-label="$t('components.input.invalidEmail')"
          @input="(e) => handleInput('email', e)"
        />
        <VerticalTitleInput
          type="textarea"
          :title="$t('sections.agents.form.message.value')"
          :placeholder="$t('sections.agents.form.message.placeholder')"
          :value="message"
          :valid="validMessage"
          :error-label="$t('components.input.missingField')"
          @input="(e) => handleInput('message', e)"
        />
        <BaseCheckbox
          :label="$t('sections.agents.form.consent')"
          :value="consent"
          @input="(e) => handleInput('consent', e)"
        />
        <BaseButton
          large
          :label="$t('sections.agents.form.submitButton')"
          :disabled="!validFields"
          class="align-self-center"
          @click="$emit('click')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  SectionHeader,
  VerticalTitleInput,
  BaseButton,
  BaseCheckbox,
} from 'ff-components-lib';
import { validateEmail } from '@/utils';

export default {
  name: 'AgentsFormSection',
  components: {
    SectionHeader,
    VerticalTitleInput,
    BaseButton,
    BaseCheckbox,
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      name: undefined,
      email: undefined,
      message: undefined,
      consent: false,
    };
  },
  computed: {
    validName() {
      return (
        this.name === undefined
        || (typeof this.name === 'string' && this.name.length > 0)
      );
    },
    validEmail() {
      if (this.email === undefined) {
        return true;
      }
      if (this.email && this.email.length > 0) {
        return validateEmail(this.email);
      }
      return false;
    },
    validMessage() {
      return (
        this.message === undefined
        || (typeof this.message === 'string'
          && this.message.length > 0)
      );
    },
    validFields() {
      return (
        this.name
        && this.validName
        && this.email
        && this.validEmail
        && this.message
        && this.validMessage
        && this.consent
      );
    },
  },
  mounted() {
    Object.keys(this.fields).map(async (field) => {
      this[field] = this.fields[field];
    });
  },
  watch: {
    fields: {
      handler() {
        Object.keys(this.fields).map(async (field) => {
          this[field] = this.fields[field];
        });
      },
      deep: true,
    },
  },
  methods: {
    handleInput(field, value) {
      this[field] = value;
      this.$emit('input', { field, value });
    },
  },
};
</script>
<style lang="scss" scoped>
.agents-form-section {
  display: flex;
  flex-direction: column;
  gap: 55px;
  padding-bottom: 102px;
  background: linear-gradient(167.49deg, #f7f7f7 32.3%, #1575bb 108.13%);

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 55px;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

@media only screen and (min-width: 1550px) {
  .agents-form-section {
    background: linear-gradient(167.49deg, #f7f7f7 32.3%, #1575bb 108.13%);
  }
}

@media only screen and (min-width: 2100px) {
  .agents-form-section {
    background: linear-gradient(172.49deg, #f7f7f7 32.3%, #1575bb 108.13%);
  }
}

@media only screen and (max-width: 770px) {
  .agents-form-section {
    padding: 0 0 96px 0;
  }
}
</style>

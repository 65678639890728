<template>
  <layout>
    <template slot="body">
      <HeroSection
        image="agent_banner.jpg"
        :title="$t('sections.agents.heroSection.title')"
        :subtitle="$t('sections.agents.heroSection.subtitle')"
        hide-input
        :is-mobile="getBreakpoints.xs"
      />

      <div class="hero-button">
        <BaseButton
          large
          :label="$t('sections.agents.heroSection.button')"
          @click="openModal"
        />
      </div>

      <AgentsStepsSection />

      <ReviewsSection
        :reviews="reviews"
        :title="$t('sections.agents.reviews.title')"
        :description="$t('sections.agents.reviews.subtitle')"
        :is-mobile="getBreakpoints.xs"
      />

      <AgentsFormSection
        :fields="fields"
        @input="handleInput"
        @click="sendAgentMessage"
      />

      <AgentsWaitingListModal
        :modal-open="agentModalOpen"
        :fields="agentFields"
        @input="handleAgentEmailInput"
        @submit="sendAgentJoinUs"
        @close="closeModal"
      />
    </template>
  </layout>
</template>
<script>
import { mapGetters } from 'vuex';
import { HeroSection, ReviewsSection, BaseButton } from 'ff-components-lib';
import Layout from '@/components/layout/Layout';

import AgentsStepsSection from '@/components/sections/external/agents/AgentsStepsSection';
import AgentsFormSection from '@/components/sections/external/agents/AgentsFormSection';
import AgentsWaitingListModal from '@/components/sections/external/agents/AgentsWaitingListModal';

export default {
  name: 'AgentsScreen',
  components: {
    Layout,
    HeroSection,
    AgentsStepsSection,
    ReviewsSection,
    BaseButton,
    AgentsFormSection,
    AgentsWaitingListModal,
  },
  data() {
    return {
      agentModalOpen: false,
      agentFields: {
        email: undefined,
        countryCode: undefined,
        phone: undefined,
      },
      fields: {
        name: undefined,
        email: undefined,
        message: undefined,
        consent: false,
      },
    };
  },
  computed: {
    ...mapGetters(['getAgentMessages', 'getBreakpoints']),
    reviews() {
      if (!this.getAgentMessages) {
        return [];
      }
      return this.getAgentMessages.map((message) => ({
        headerText: message.name,
        text: message.message,
      }));
    },
  },
  async mounted() {
    this.$store.dispatch('storeLoading', true);
    await this.$store.dispatch('storeAgentMessages');
    this.$store.dispatch('storeLoading', false);
  },
  methods: {
    handleInput({ field, value }) {
      this.fields[field] = value;
    },
    async sendAgentMessage() {
      try {
        await this.$store.dispatch('sendAgentMessageEmail', {
          name: this.fields.name,
          email: this.fields.email,
          message: this.fields.message,
        });

        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.messageSuccess'),
          type: 'success',
        });

        this.resetFields();
      } catch (e) {
        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.error'),
          type: 'error',
        });
      }
    },
    resetFields() {
      this.fields = {
        name: undefined,
        email: undefined,
        message: undefined,
      };
    },
    openModal() {
      this.agentModalOpen = true;
    },
    closeModal() {
      this.agentModalOpen = false;
      this.agentFields = {
        email: undefined,
        countryCode: undefined,
        phone: undefined,
      };
    },
    handleAgentEmailInput({ field, value }) {
      this.agentFields[field] = value;
    },
    async sendAgentJoinUs() {
      try {
        await this.$store.dispatch('sendAgentWaitingListEmail', {
          email: this.agentFields.email,
          countryCode: this.agentFields.countryCode,
          phone: this.agentFields.phone,
        });

        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.messageSuccess'),
          type: 'success',
        });

        this.closeModal();
      } catch (e) {
        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.error'),
          type: 'error',
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.hero-button {
  display: flex;
  justify-content: center;
  margin-top: -28px;
  z-index: 2;
}

.steps-section-wrapper {
  margin-top: 120px;
  margin-bottom: 120px;
}

.reviews-section-wrapper {
  margin-top: 80px;
  margin-bottom: 0;
}

@media only screen and (max-width: 770px) {
  .hero-button {
    margin-top: -22.5px;
  }

  .steps-section-wrapper {
    margin-top: 60px;
    margin-bottom: 40px;
  }

  .reviews-section-wrapper {
    margin-bottom: 32px;
  }
}
</style>

<template>
  <div class="steps-section-wrapper">
    <div class="margin-container d-flex justify-content-center">
      <SectionHeader
        :title="$t('sections.agents.stepsSection.title')"
        :description="$t('sections.agents.stepsSection.subtitle')"
      />
    </div>
    <div class="gradient-container">
      <div class="gradient-bg-section" />
      <div class="top-substract" />
      <div class="bottom-substract" />
      <div class="steps-section">
        <div
          v-for="(step, index) in $t('sections.agents.stepsSection.steps')"
          :key="`agents-owner-step-${index}`"
          class="step"
        >
          <StepsPointSmall
            :title="step.title"
            :description="step.description"
            :icon="`rentSteps/rent_owner_step${index + 1}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { StepsPointSmall, SectionHeader } from 'ff-components-lib';

export default {
  name: 'AgentsStepsSection',
  components: {
    StepsPointSmall,
    SectionHeader,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.steps-section-wrapper {
  padding-bottom: 60px;

  .section-header {
    z-index: 10;
  }

  .steps-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    z-index: 10;

    .step {
      width: 33%;
      min-width: 384px;
      display: flex;
      justify-content: center;
      margin-bottom: 72px;
    }
  }

  .bottom-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 26px;

    .base-button {
      z-index: 10;
      background-color: rgba($tertiary, 0.9);
      box-shadow: 0px 4px 30px rgba($primary, 0.4);
    }

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      .base-button {
        background-color: rgba($tertiary, 0.8);
        -webkit-backdrop-filter: blur(48px);
        backdrop-filter: blur(48px);
      }
    }

    .end-phrase {
      z-index: 10;
      font-size: 18px;
      line-height: 26px;
      color: $grey;
    }
  }

  .gradient-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 836px;
    display: flex;
    align-items: center;
    margin-top: -65px;
    margin-bottom: -145px;

    .top-substract {
      background: $tertiary;
      position: absolute;
      top: 0;
      right: -5%;
      width: 110%;
      height: 120px;
      border-bottom-left-radius: 100%;
      border-bottom-right-radius: 100%;
    }

    .bottom-substract {
      background: $tertiary;
      position: absolute;
      bottom: 0;
      right: -5%;
      width: 110%;
      height: 120px;
      border-top-left-radius: 100%;
      border-top-right-radius: 100%;
    }

    .gradient-bg-section {
      position: absolute;
      width: 100%;
      height: 760px;
      background: linear-gradient(
        180deg,
        $tertiary 0%,
        $primary 100%
      );
      top: 0;
    }
  }
}

@media only screen and (max-width: 1150px) {
  .steps-section-wrapper {
    .steps-section {
      gap: 20px;
    }

    .gradient-container {
      height: 1236px;

      .gradient-bg-section {
        height: 1236px;
      }
    }
  }
}

@media only screen and (max-width: 770px) {
  .steps-section-wrapper {
    padding-bottom: 56px;

    .section-header {
      width: 100%;
    }

    .steps-section {
      flex-direction: column;
      align-items: center;
      gap: 48px;

      .step {
        margin-bottom: 0;
      }
    }

    .bottom-section {
      gap: 24px;

      .end-phrase {
        font-size: 12px;
        line-height: 18px;
      }
    }

    .gradient-container {
      height: 1476px;
      margin-top: -65px;
      margin-bottom: -145px;

      .top-substract {
        height: 120px;
      }

      .bottom-substract {
        height: 120px;
      }

      .gradient-bg-section {
        height: 1476px;
        top: 0;
      }
    }
  }
}
</style>
